import { render, staticRenderFns } from "./StudentList.vue?vue&type=template&id=0915e2e8&"
import script from "./StudentList.vue?vue&type=script&lang=js&"
export * from "./StudentList.vue?vue&type=script&lang=js&"
import style0 from "./StudentList.vue?vue&type=style&index=0&lang=css&"
import style1 from "./StudentList.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports